@use './../abstracts/' as *;

@media (max-width: 576px) {
  @include grid;

  .detail {
    border-radius: 15px;
    height: 200px;
    margin-top: 10px;
    margin-bottom: 30px;

    margin: 10px 0% 10px -18px;

    /* background-image: url('./img/detail1.png'); */
  }

  .detail-inner {
    background-color: rgba(143, 192, 169, 0.7);
    border-radius: 15px;
    height: 170px;
    padding-top: 25px;
    margin: 4% 4%;
    float: left;
    width: 92%;
  }
}

@media (min-width: 576px) {
  @include grid;

  .detail {
    border-radius: 15px;
    width: 100%;
    display: block;
    height: 220px;
    margin-bottom: 20px;
  }

  .detail-inner {
    background-color: rgba(143, 192, 169, 0.7);
    border-radius: 15px;
    width: 92%;
    height: 180px;
    padding-top: 25px;
    padding-right: 5px;
    margin: 4% 4%;
    float: left;
    width: 92%;
  }
}

@media (min-width: 768px) {
  @include grid;

  .detail {
    border-radius: 15px;
    height: 260px;

    margin: 10px 2% 40px 2%;
    /* background-image: url('./img/detail1.png'); */
  }

  .detail-inner {
    background-color: rgba(143, 192, 169, 0.7);
    width: 92%;
    border-radius: 15px;
    height: 243px;
    padding-top: 25px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 4% 4%;
    float: left;
  }
}

@media (min-width: 992px) {
  @include grid;

  .detail {
    border-radius: 15px;
    height: 220px;
    margin-top: 10px;
    margin-bottom: 30px;

    margin: 10px 2% 10px 2%;
    /* background-image: url('./img/detail1.png'); */
  }

  .detail-inner {
    background-color: rgba(143, 192, 169, 0.7);
    border-radius: 15px;
    height: 197px;
    padding-top: 25px;
    margin: 4% 4%;
    float: left;
    width: 92%;
  }
}

@media (min-width: 1200px) {
  @include grid;

  .detail {
    border-radius: 15px;
    height: 220px;
    margin-top: 10px;
    margin-bottom: 30px;

    margin: 10px 2% 10px 2%;
    /* background-image: url('./img/detail1.png'); */
  }

  .detail-inner {
    background-color: rgba(143, 192, 169, 0.7);
    border-radius: 15px;
    height: 190px;
    padding-top: 25px;
    margin: 4% 4%;
    float: left;
    width: 92%;
  }
}
