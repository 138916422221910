@use './../abstracts/' as *;

.header {
  width: 100%;
  background-color: #8fc0a9;
  height: 50px;
}

.header #domain {
  color: white;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 31px;
  float: left;
  padding-left: 17px;
  padding-top: 2px;
  display: inline-block;
}
