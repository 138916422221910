@use './../abstracts/' as *;

@media (max-width: 576px) {
  .popup {
    float: left;
    position: relative;
    width: 90%;
    height: 420px;
    background-color: #c8d5b9;
    margin-top: -500px;
    margin-left: 5%;
    border-radius: 15px;

    img {
      width: 20%;
      height: auto;
      margin: 25px;
      display: inline;
      float: left;
      border-radius: 15px;
    }

    h1 {
      display: inline-block;
      margin-top: 18px;
      font-size: 24px;
      width: 40%;
      text-align: left;
    }

    .popup-description {
      display: block;
      position: absolute;
      left: 7%;
      top: 130px;
      width: 90%;
    }

    button {
      color: gray;
      background-color: #c8d5b9;
      border-radius: 5px;
      position: absolute;
      margin: 20px;
      right: 0%;
    }
  }
}

@media (min-width: 576px) {
  .popup {
    float: left;
    position: relative;
    width: 90%;
    height: 370px;
    background-color: #c8d5b9;
    margin-top: -450px;
    margin-left: 5%;
    border-radius: 15px;

    img {
      width: 20%;
      height: auto;
      margin: 25px;
      display: inline;
      float: left;
      border-radius: 15px;
    }

    h1 {
      display: inline-block;
      margin-top: 18px;
      font-size: 24px;
      width: 50%;
      text-align: left;
    }

    .popup-description {
      display: block;
      position: absolute;
      left: 7%;
      top: 130px;
      width: 90%;
    }

    button {
      color: gray;
      background-color: #c8d5b9;
      border-radius: 5px;
      position: absolute;
      margin: 20px;
      right: 0%;
    }
  }
}

@media (min-width: 768px) {
  .popup {
    float: left;
    position: relative;
    width: 90%;
    height: 300px;
    background-color: #c8d5b9;
    margin-top: -450px;
    margin-left: 5%;
    border-radius: 15px;

    img {
      width: 15%;
      height: auto;
      margin: 25px;
      display: inline;
      float: left;
      border-radius: 15px;
    }

    h1 {
      display: inline-block;
      margin-top: 18px;
      font-size: 24px;
      width: 50%;
      text-align: left;
    }

    .popup-description {
      display: block;
      position: absolute;
      left: 4%;
      top: 130px;
      width: 90%;
    }

    button {
      color: gray;
      background-color: #c8d5b9;
      border-radius: 5px;
      position: absolute;
      margin: 20px;
      right: 0%;
    }
  }
}

@media (min-width: 992px) {
  .popup {
    float: left;
    position: relative;
    width: 90%;
    height: 270px;
    background-color: #c8d5b9;
    margin-top: -450px;
    margin-left: 5%;
    border-radius: 15px;

    img {
      width: 10%;
      height: auto;
      margin: 25px;
      display: inline;
      float: left;
      border-radius: 15px;
    }

    h1 {
      display: inline-block;
      margin-top: 18px;
      font-size: 24px;
      width: 50%;
      text-align: left;
    }

    .popup-description {
      display: block;
      position: absolute;
      left: 25px;
      top: 130px;
      width: 90%;
    }

    button {
      color: gray;
      background-color: #c8d5b9;
      border-radius: 5px;
      position: absolute;
      margin: 20px;
      right: 0%;
    }
  }
}

@media (min-width: 1100px) {
  .popup {
    float: left;
    position: relative;
    width: 90%;
    height: 270px;
    background-color: #c8d5b9;
    margin-top: -450px;
    margin-left: 5%;
    border-radius: 15px;

    img {
      width: 10%;
      height: auto;
      margin: 25px;
      display: inline;
      float: left;
      border-radius: 15px;
    }

    h1 {
      display: inline-block;
      margin-top: 18px;
      font-size: 24px;
      width: 50%;
      text-align: left;
    }

    .popup-description {
      display: block;
      position: absolute;
      left: 25px;
      top: 120px;
      width: 90%;
    }

    button {
      color: gray;
      background-color: #c8d5b9;
      border-radius: 5px;
      position: absolute;
      margin: 20px;
      right: 0%;
    }
  }
}

@media (min-width: 1200px) {
  .popup {
    float: left;
    position: relative;
    width: 90%;
    height: 270px;
    background-color: #c8d5b9;
    margin-top: -450px;
    margin-left: 5%;
    border-radius: 15px;

    img {
      width: 10%;
      height: auto;
      margin: 25px;
      display: inline;
      float: left;
      border-radius: 15px;
    }

    h1 {
      display: inline-block;
      margin-top: 18px;
      font-size: 24px;
      width: 50%;
      text-align: left;
    }

    .popup-description {
      display: block;
      position: absolute;
      left: 25px;
      top: 140px;
      width: 90%;
    }

    button {
      color: gray;
      background-color: #c8d5b9;
      border-radius: 5px;
      position: absolute;
      margin: 20px;
      right: 0%;
    }
  }
}

@media (min-width: 1400px) {
  .popup {
    float: left;
    position: relative;
    width: 90%;
    height: 270px;
    background-color: #c8d5b9;
    margin-top: -450px;
    margin-left: 5%;
    border-radius: 15px;

    img {
      width: 10%;
      height: auto;
      margin: 25px;
      display: inline;
      float: left;
      border-radius: 15px;
    }

    h1 {
      display: inline-block;
      margin-top: 18px;
      font-size: 24px;
      width: 50%;
      text-align: left;
    }

    .popup-description {
      display: block;
      position: absolute;
      left: 25px;
      top: 150px;
      width: 90%;
    }

    button {
      color: gray;
      background-color: #c8d5b9;
      border-radius: 5px;
      position: absolute;
      margin: 20px;
      right: 0%;
    }
  }
}
