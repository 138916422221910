@use './../abstracts/' as *;

.footer {
  background-color: #c8d5b9;
  margin: 20px auto;
  width: 100%;
  text-align: center;
  padding: 10px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.footer h1 {
  padding-top: 5px;
  font-family: 'Roboto';
  font-weight: 700;
}
