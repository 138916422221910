@use './../abstracts/' as *;

#carousel {
  margin-bottom: 25px;
}

.carousel-clients {
  width: 90%;
  height: 90%;
  margin: 10px auto;
}
