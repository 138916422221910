@use './../abstracts/' as *;

@media (max-width: 576px) {
  .btn-grid {
    width: 92%;
    margin: 0 auto;
    height: 60px;
    margin-top: -15px;
    margin-bottom: 20px;
    margin-right: 1%;
    margin-left: 2%;
  }
}

.grid-row-btn-v {
  text-decoration: none;
  margin-top: 10px;
  text-align: center;
  background-color: $gridRowBtn;
  border-radius: 15px;
  color: white;
  height: 60px;
  padding-top: 10px;
  width: 100%;
}

.grid-row-btn-v-selected {
  text-decoration: none;
  margin-top: 10px;
  text-align: center;
  background-color: $gridRowBtnSelected;
  border-radius: 15px;
  color: white;
  height: 60px;
  padding-top: 10px;
  width: 100%;
}

.grid-row-btn-v button {
  text-decoration: none;
  font-size: 22px;
  padding-bottom: 20px;
  border: none;
  text-align: center;
  background-color: $gridRowBtn;
  border-radius: 15px;
  height: 60px;
  width: 100%;
  color: rgb(91, 89, 89);
  padding-top: 10px;
}

.grid-row-btn-v-selected button {
  text-decoration: none;
  font-size: 22px;
  padding-bottom: 20px;
  border: none;
  text-align: center;
  border-radius: 15px;
  height: 60px;
  width: 100%;
  color: white;
  padding-top: 10px;
}
