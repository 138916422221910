@use './../abstracts/' as *;

@media (max-width: 576px) {
  .education-box {
    display: inline-block;
    width: 95%;
    vertical-align: top;
    background-color: #c8d5b9;
    margin: 5px 5px;
    border-radius: 15px;
    padding-top: 10px;
    padding-bottom: 10px;

    h4 {
      color: $lightText;
      text-align: center;
    }

    p {
      color: $lightText;
      text-align: center;
    }
  }
}
@media (min-width: 576px) {
  .education-box {
    display: inline-block;
    width: 90%;
    vertical-align: top;
    background-color: #c8d5b9;
    margin: 5px 5px;
    border-radius: 15px;
    padding-top: 10px;
    padding-bottom: 10px;

    h4 {
      color: $lightText;
      text-align: center;
    }

    p {
      color: $lightText;
      text-align: center;
    }
  }
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
  .education-box {
    display: inline-block;
    width: 45%;
    vertical-align: top;
    background-color: #c8d5b9;
    margin: 5px 5px;
    border-radius: 15px;
    padding-top: 10px;
    padding-bottom: 10px;

    h4 {
      color: $lightText;
      text-align: center;
    }

    p {
      color: $lightText;
      text-align: center;
    }
  }
}

.education {
  width: 97%;
  height: 100%;
  border-radius: 15px;
  background-color: #8fc0a9;
  margin: 0 auto;
  padding-top: 15px;
  padding-bottom: 10px;
  clear: both;
  text-align: center;
}

.education h3 {
  text-align: center;
  color: $lightText;
}
