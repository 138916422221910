@use './../abstracts/' as *;

@media (max-width: 576px) {
  .display {
    height: 170px;
    background-image: url('../../img/header.png');

    .display-title {
      color: $lightText;
      font-family: $font;
      font-weight: 700;
      font-size: 40px;
      text-align: center;
      width: 90%;
      padding-top: 40px;
      margin: 0 auto;
      text-shadow: 2px 2px black;
    }
  }
}
@media (min-width: 576px) {
  .display {
    height: 170px;
    background-image: url('../../img/header.png');

    .display-title {
      color: $lightText;
      font-family: $font;
      font-weight: 700;
      font-size: 40px;
      text-align: center;
      width: 90%;
      padding-top: 40px;
      margin: 0 auto;
      text-shadow: 2px 2px black;
    }
    .display-sub {
      color: $lightText;
      font-family: $font;
      font-weight: 700;
      font-size: 30px;
      text-align: center;
      width: 70%;
      margin: 0 auto;
      text-shadow: 2px 2px black;
    }
  }
}

@media (min-width: 768px) {
  .display {
    height: 320px;
    background-image: url('../../img/header.png');
    .display-title {
      color: $lightText;
      font-family: $font;
      font-weight: 700;
      font-size: 80px;
      text-align: center;
      width: 90%;
      margin: 0 auto;
      text-shadow: 2px 2px black;
    }
    .display-sub {
      color: $lightText;
      font-family: $font;
      font-weight: 700;
      font-size: 30px;
      text-align: center;
      width: 70%;
      margin: 0 auto;
      text-shadow: 2px 2px black;
    }
  }
}

@media (min-width: 992px) {
  .display {
    height: 220px;
    background-image: url('../../img/header.png');

    .display-title {
      color: $lightText;
      font-family: $font;
      font-weight: 700;
      font-size: 80px;
      text-align: center;
      width: 90%;
      margin: 0 auto;
      text-shadow: 2px 2px black;
    }
    .display-sub {
      color: $lightText;
      font-family: $font;
      font-weight: 700;
      font-size: 30px;
      text-align: center;
      width: 70%;
      margin: 0 auto;
      text-shadow: 2px 2px black;
    }
  }
}
